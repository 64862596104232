"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function get() {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  }

  __setModuleDefault(result, mod);

  return result;
};

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchContentAggregateList = void 0;

var basic_frontend_common_1 = require("basic-frontend-common");

var react_1 = __importStar(require("react"));

var constants_1 = require("../../constants");

var service_clients_1 = require("../../service-clients");

var list_container_1 = require("./list-container");

var SearchContentAggregateList = function SearchContentAggregateList(_a) {
  var searchTerm = _a.searchTerm,
      language = _a.language,
      theme = _a.theme;

  var _b = __read((0, react_1.useState)(), 2),
      isLoading = _b[0],
      setLoadingState = _b[1];

  var _c = __read((0, react_1.useState)(), 2),
      contentList = _c[0],
      setContentList = _c[1];

  var searchArticles = function searchArticles(searchTerm) {
    if (isLoading) {
      return;
    }

    setLoadingState(true);
    (0, service_clients_1.searchArchiveContentAggregate)(searchTerm, 0, constants_1.PUBLIC_SKIP_LIMIT, language).then(function (res) {
      setContentList(res);
    });
    setLoadingState(false);
  };

  (0, react_1.useEffect)(function () {
    setContentList([]);

    if (searchTerm.length >= 3) {
      searchArticles(searchTerm);
    }
  }, [searchTerm, language]);
  return react_1["default"].createElement(react_1["default"].Fragment, null, contentList && react_1["default"].createElement(list_container_1.ListContainer, {
    list: contentList,
    theme: theme,
    language: language
  }), contentList && contentList.length > 10 && react_1["default"].createElement(react_1["default"].Fragment, null, react_1["default"].createElement(basic_frontend_common_1.Spacer, null), react_1["default"].createElement(basic_frontend_common_1.ToTopLink, null), react_1["default"].createElement(basic_frontend_common_1.Spacer, null)));
};

exports.SearchContentAggregateList = SearchContentAggregateList;