"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function get() {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  }

  __setModuleDefault(result, mod);

  return result;
};

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListContainer = void 0;

var basic_frontend_common_1 = require("basic-frontend-common");

var react_1 = __importStar(require("react"));

var react_tagcloud_1 = require("react-tagcloud");

var service_clients_1 = require("../../service-clients");

var suspense_wrapper_1 = require("../suspense-wrapper");

var list_item_1 = require("./list-item");

var ListContainer = function ListContainer(_a) {
  var list = _a.list,
      theme = _a.theme,
      language = _a.language;

  var _b = __read((0, react_1.useState)(), 2),
      facetContainer = _b[0],
      setFacetContainer = _b[1];

  var _c = __read((0, react_1.useState)(), 2),
      archiveContentList = _c[0],
      setArchiveContentList = _c[1];

  var facetChange = function facetChange(e) {
    (0, service_clients_1.getArchiveContentAggregateByTopic)(e.value, 0, 100, language).then(function (res) {
      return setArchiveContentList(res);
    });
  };

  var loadTagCloudList = function loadTagCloudList() {
    var tagCloud = [];

    for (var _i = 0; _i < facetContainer.length; _i++) {
      tagCloud[_i] = {
        value: facetContainer[_i].keyword,
        count: facetContainer[_i].count
      };
    }

    return tagCloud.filter(function (thing, i, arr) {
      return arr.indexOf(arr.find(function (t) {
        return t.value === thing.value;
      })) === i;
    });
  };

  var mappedItems = archiveContentList && archiveContentList.map(function (item, index) {
    return react_1["default"].createElement(list_item_1.ListItem, {
      item: item,
      key: index,
      theme: theme,
      language: language
    });
  });
  (0, react_1.useEffect)(function () {
    setFacetContainer(list);
  }, [list]);
  var options = {
    luminosity: "light",
    hue: "monochrome"
  };
  return react_1["default"].createElement(suspense_wrapper_1.SuspenseWrapper, {
    theme: theme
  }, facetContainer && react_1["default"].createElement(react_tagcloud_1.TagCloud, {
    minSize: 18,
    maxSize: 44,
    tags: loadTagCloudList(),
    shuffle: false,
    onClick: function onClick(tag) {
      return facetChange(tag);
    },
    colorOptions: options
  }), react_1["default"].createElement("ul", {
    className: "mt-4"
  }, facetContainer && mappedItems), facetContainer && mappedItems && react_1["default"].createElement(react_1["default"].Fragment, null, react_1["default"].createElement(basic_frontend_common_1.Spacer, null), react_1["default"].createElement(basic_frontend_common_1.ToTopLink, null), react_1["default"].createElement(basic_frontend_common_1.Spacer, null)));
};

exports.ListContainer = ListContainer;