"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Permissions = void 0;
var Permissions;

(function (Permissions) {
  Permissions[Permissions["ADMIN"] = 0] = "ADMIN";
  Permissions[Permissions["POWER"] = 1] = "POWER";
  Permissions[Permissions["USER"] = 2] = "USER";
})(Permissions = exports.Permissions || (exports.Permissions = {}));