"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function get() {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  }

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModalContainer = void 0;

var react_1 = __importStar(require("react"));

var ModalContainer = function ModalContainer(_a) {
  var children = _a.children,
      isModalOpen = _a.isModalOpen,
      onCloseClicked = _a.onCloseClicked,
      theme = _a.theme;
  (0, react_1.useEffect)(function () {
    var handleEsc = function handleEsc(event) {
      if (event.keyCode === 27) {
        onCloseClicked(!!isModalOpen);
      }
    };

    window.addEventListener('keydown', handleEsc);
    return function () {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
  var styleOverlay = "fixed inset-0 transition-opacity ease-out ".concat(isModalOpen ? 'duration-300 opacity-100' : 'duration-200 opacity-0');
  var styleModal = "inline-block align-top rounded-lg text-left overflow-y-auto shadow-xl transform transition-all ".concat(isModalOpen ? 'ease-out duration-300 opacity-100 translate-y-0 sm:scale-100' : 'ease-in duration-200 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95', " ").concat(theme ? theme.form.backgroundColor : 'bg-transparent', " max-w-xl w-full sm:w-10/12 md:w-9/12");
  return react_1["default"].createElement(react_1["default"].Fragment, null, isModalOpen && react_1["default"].createElement("section", {
    className: "fixed z-10 inset-0 overflow-hidden"
  }, react_1["default"].createElement("div", {
    className: "py-0 px-4 text-center"
  }, react_1["default"].createElement("div", {
    className: styleOverlay,
    onClick: function onClick() {
      return onCloseClicked(!isModalOpen);
    }
  }, react_1["default"].createElement("div", {
    className: "absolute inset-0 bg-gray-500 opacity-75"
  })), react_1["default"].createElement("span", {
    className: "hidden"
  }), "\u200B", react_1["default"].createElement("div", {
    className: styleModal,
    role: "dialog",
    "aria-modal": "true",
    "aria-labelledby": "modal-headline"
  }, children))));
};

exports.ModalContainer = ModalContainer;