"use strict";

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDebounce = exports.debounce = exports.debouncePromise = void 0;

var react_1 = require("react");

var constants_1 = require("../constants");

var debouncePromise = function debouncePromise(func, delay) {
  if (delay === void 0) {
    delay = constants_1.DEBOUNCE_MOBILE_DELAY;
  }

  var timerID;
  return function () {
    var args = [];

    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }

    return new Promise(function (resolve, reject) {
      if (timerID) {
        clearTimeout(timerID);
      }

      timerID = window.setTimeout(function () {
        timerID = null;
        func.apply(void 0, __spreadArray([], __read(args), false)).then(resolve)["catch"](reject);
      }, delay);
    });
  };
};

exports.debouncePromise = debouncePromise;

var debounce = function debounce(func, delay) {
  if (delay === void 0) {
    delay = constants_1.DEBOUNCE_MOBILE_DELAY;
  }

  var timerID;
  return function () {
    var args = [];

    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }

    if (timerID) {
      clearTimeout(timerID);
    }

    timerID = window.setTimeout(function () {
      timerID = null;
      func.apply(void 0, __spreadArray([], __read(args), false));
    }, delay);
  };
};

exports.debounce = debounce;

var useDebounce = function useDebounce(func, delay) {
  if (delay === void 0) {
    delay = constants_1.DEBOUNCE_MOBILE_DELAY;
  }

  var _a = __read((0, react_1.useState)(null), 2),
      timerID = _a[0],
      setTimerID = _a[1];

  (0, react_1.useEffect)(function () {
    return function () {
      if (timerID) {
        clearTimeout(timerID);
      }
    };
  }, [timerID]);
  return function () {
    var args = [];

    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }

    if (timerID) {
      clearTimeout(timerID);
    }

    var newTimerID = window.setTimeout(function () {
      setTimerID(null);
      func.apply(void 0, __spreadArray([], __read(args), false));
    }, delay);
    setTimerID(newTimerID);
  };
};

exports.useDebounce = useDebounce;