"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ThemeMasterArchive = void 0;
exports.ThemeMasterArchive = {
  body: {
    textColor: "text-gray-100",
    textSecondaryColor: "text-gray-200",
    backgroundColor: "bg-gray-800",
    borderColor: "border-gray-100"
  },
  link: {
    textColor: "text-gray-100",
    focusColor: "text-red-500",
    hoverColor: "text-red-500",
    activeColor: "text-red-500",
    visitedColor: "text-gray-100"
  },
  form: {
    textColor: "text-white",
    textErrorColor: "text-red-500",
    textSecondaryColor: "text-gray-200",
    placeholderColor: "placeholder-white",
    backgroundColor: "bg-transparent",
    borderColor: "bg-gray-200",
    borderHoverColor: "border-red-500"
  },
  format: {
    codeTextColor: "text-gray-100",
    codeBorderColor: "bg-transparent",
    codeBackgroundColor: "border-gray-100"
  }
};