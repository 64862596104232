"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getArchiveUserSkeleton = exports.getUserInfoFormData = void 0;

var js_sha256_1 = require("js-sha256");

var service_clients_1 = require("../service-clients");

var auth0_provider_1 = require("./auth0-provider");

var getUserInfoFormData = function getUserInfoFormData() {
  var userInfo = (0, auth0_provider_1.getUserInfo)();
  var formData = new FormData();
  formData.append("UserName", userInfo ? userInfo.UserName : "anonymous");
  formData.append("EmailAddress", userInfo ? userInfo.EmailAddress : "anonymous");
  formData.append("Sub", userInfo ? (0, js_sha256_1.sha256)(userInfo.Sub) : (0, js_sha256_1.sha256)("anonymous"));
  return formData;
};

exports.getUserInfoFormData = getUserInfoFormData;

var getArchiveUserSkeleton = function getArchiveUserSkeleton() {
  return {
    EmailAddress: "you@email.com",
    NickName: "You",
    Permission: service_clients_1.Permissions.USER,
    Sub: "Sub",
    UserName: "Username",
    UserType: 0,
    City: "The City",
    Country: "Switzerland",
    DateOfBirth: "2020-01-01T00:00:00",
    FirstName: "Firstname",
    Number: "1",
    PostCode: "0000",
    Street: "The Street",
    SurName: "SurName"
  };
};

exports.getArchiveUserSkeleton = getArchiveUserSkeleton;