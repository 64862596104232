"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function get() {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  }

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Auth0Wrapper = void 0;

var react_1 = __importStar(require("react"));

var auth0_provider_1 = require("./auth0-provider");

var Auth0Wrapper = function Auth0Wrapper(_a) {
  var children = _a.children;
  (0, react_1.useEffect)(function () {
    if (!(0, auth0_provider_1.isLoggedIn)()) {
      document.location.replace("/login");
    }
  });
  return react_1["default"].createElement(react_1["default"].Fragment, null, (0, auth0_provider_1.isLoggedIn)() && children);
};

exports.Auth0Wrapper = Auth0Wrapper;