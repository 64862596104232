"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function get() {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  }

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SuspenseWrapper = void 0;

var basic_frontend_common_1 = require("basic-frontend-common");

var react_1 = __importStar(require("react"));

var constants_1 = require("../../constants");

var SuspenseWrapper = function SuspenseWrapper(_a) {
  var theme = _a.theme,
      children = _a.children;
  return react_1["default"].createElement(react_1.Suspense, {
    fallback: react_1["default"].createElement(basic_frontend_common_1.Spinner, {
      backgroundImage: constants_1.BG_IMAGE,
      theme: theme
    })
  }, children);
};

exports.SuspenseWrapper = SuspenseWrapper;