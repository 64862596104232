"use strict"; // https://www.digitalocean.com/community/tutorials/code-formatting-with-prettier-in-visual-studio-code

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.API_PARCEL = exports.API_URL = exports.PUBLIC_URL = exports.PAGE_ORIGIN = exports.APP_VERSION = exports.GOOGLE_KEY = exports.ANALYTICS_COOKIE_KEY = exports.DEBOUNCE_DESKTOP_DELAY = exports.DEBOUNCE_MOBILE_DELAY = exports.AUTH_ERROR_KEY = exports.USER_INFO_KEY = exports.ACCESS_TOKEN_KEY = exports.ID_TOKEN_KEY = exports.DESKTOP_VIEW_WIDTH = exports.TABLET_VIEW_WIDTH = exports.MOBILE_VIEW_WIDTH = exports.DEFAULT_LIST_ORDER = exports.PRIVATE_SCROLL_LIMIT = exports.PRIVATE_SKIP_LIMIT = exports.PRIVATE_SELECT_LIMIT = exports.PUBLIC_SCROLL_LIMIT = exports.PUBLIC_SKIP_LIMIT = exports.PUBLIC_SELECT_LIMIT = exports.ICON_FILE = exports.BG_IMAGE = exports.PAGE_KEYWORDS = exports.PAGE_TOPIC = exports.PAGE_DESCRIPTION = exports.PAGE_AUTHOR = exports.PAGE_NAME = void 0;
exports.PAGE_NAME = 'Master Archive';
exports.PAGE_AUTHOR = 'responsive IT';
exports.PAGE_DESCRIPTION = 'finest responsive News';
exports.PAGE_TOPIC = 'Collected News-Feeds';
exports.PAGE_KEYWORDS = 'news, responsive, react, it, information, archive';
exports.BG_IMAGE = '/IMG_5034.JPG';
exports.ICON_FILE = '/icon.png'; //

exports.PUBLIC_SELECT_LIMIT = 50;
exports.PUBLIC_SKIP_LIMIT = 50;
exports.PUBLIC_SCROLL_LIMIT = 5; //

exports.PRIVATE_SELECT_LIMIT = 50;
exports.PRIVATE_SKIP_LIMIT = 50;
exports.PRIVATE_SCROLL_LIMIT = 8; //

exports.DEFAULT_LIST_ORDER = 'desc'; //

exports.MOBILE_VIEW_WIDTH = 468;
exports.TABLET_VIEW_WIDTH = 768;
exports.DESKTOP_VIEW_WIDTH = 1024; //

exports.ID_TOKEN_KEY = 'id_token';
exports.ACCESS_TOKEN_KEY = 'access_token';
exports.USER_INFO_KEY = 'user_info';
exports.AUTH_ERROR_KEY = 'auth_error_key';
exports.DEBOUNCE_MOBILE_DELAY = 500;
exports.DEBOUNCE_DESKTOP_DELAY = 300; // --------------------------------------------------------------------------------------
// DEV constants
// --------------------------------------------------------------------------------------
// export const ANALYTICS_COOKIE_KEY = 'dev_master_archive_analytics';
// export const GOOGLE_KEY = 'G-PYJHFF8KHL';
// export const APP_VERSION = '0.8.1-development';
// export const PAGE_ORIGIN = 'master-archive.local';
// export const PUBLIC_URL = 'http://${PAGE_ORIGIN}';
// export const API_URL = 'https://test-api-master-archive.azurewebsites.net';
// export const API_PARCEL = '80Muze3fQb9acFuJDzgOehjTx6DyRb0iCfiOWufHIZK7udecUndiRu8iq2Eevq3pZAPufYoUnmPf6ZTNZ9wPsTzeA/Btf41pNLLxGvmOlb57eAn5XeB552Zuslo4zBzlzB8DzZLwNNRsLa3/7+ICclj3HDxmHRimG9XgphjA+TRBtWOK64YBlK0o2O6+v/RwqdhfW0+NGWLiIrZxasiGQnVUbkbzYgFURS/P4GX0MCf4EyRn9YK34L6S8/GL3TXn';
// --------------------------------------------------------------------------------------
// PROD constants
// --------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------

exports.ANALYTICS_COOKIE_KEY = 'prod_master_archive_analytics';
exports.GOOGLE_KEY = 'G-ZB0H7BFGMC';
exports.APP_VERSION = '0.8.1-development';
exports.PAGE_ORIGIN = 'master-archive.news';
exports.PUBLIC_URL = "https://".concat(exports.PAGE_ORIGIN);
exports.API_URL = "https://test-api-master-archive.azurewebsites.net";
exports.API_PARCEL = '80Muze3fQb9acFuJDzgOehjTx6DyRb0iCfiOWufHIZK7udecUndiRu8iq2Eevq3pZAPufYoUnmPf6ZTNZ9wPsTzeA/Btf41pNLLxGvmOlb57eAn5XeB552Zuslo4zBzlzB8DzZLwNNRsLa3/7+ICclj3HDxmHRimG9XgphjA+TRBtWOK64YBlK0o2O6+v/RwqdhfW0+NGWLiIrZxasiGQnVUbkbzYgFURS/P4GX0MCf4EyRn9YK34L6S8/GL3TXn'; // --------------------------------------------------------------------------------------