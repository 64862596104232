import { NavigationItems } from 'basic-frontend-common';
import { TEXTS } from '../localization';

export const MenuItems = () => {
  const menuItems: NavigationItems[] = [
    {
      link: '/',
      text: TEXTS.siteParts.menuItems.linkHome,
      title: TEXTS.siteParts.menuItems.linkHomeTitle,
    },
    {
      link: '/monthly',
      text: TEXTS.siteParts.menuItems.linkMonthly,
      title: TEXTS.siteParts.menuItems.linkMonthlyTitle,
    },
    {
      link: '/sources',
      text: TEXTS.siteParts.menuItems.linkSources,
      title: TEXTS.siteParts.menuItems.linkSourcesTitle,
    },
    {
      link: '/search',
      text: TEXTS.siteParts.menuItems.linkSearch,
      title: TEXTS.siteParts.menuItems.linkSearchTitle,
    },
    {
      link: '/about',
      text: TEXTS.siteParts.menuItems.linkAbout,
      title: TEXTS.siteParts.menuItems.linkAboutTitle,
    },
  ];
  
  return menuItems;
};
