import {
  CookieBanner,
  getLanguage,
  isCookieSet,
  ThemeTransparent,
} from 'basic-frontend-common';
import {
  ANALYTICS_COOKIE_KEY,
  isLoggedIn,
  PageContentBySourceList,
  PAGE_NAME,
} from 'master-archive-common';
import React, { FC } from 'react';
import { TEXTS } from '../localization';

export const ContentBySource: FC<{ path?: String }> = ({ path }) => {
  console.log('Dynamic - path : ', path);

  const pathName = location.pathname;
  const splitted = pathName.split('/');

  const sourceId = Number(splitted[2]);

  const language = getLanguage();
  const userIsLoggedIn = isLoggedIn();
  return (
    <>
      <title>
        {TEXTS.pages.home.title} - {PAGE_NAME}
      </title>
      <CookieBanner
        theme={ThemeTransparent}
        language={language}
        isCookieSet={isCookieSet(ANALYTICS_COOKIE_KEY)}
        cookieKey={ANALYTICS_COOKIE_KEY}
      >
        <PageContentBySourceList
          theme={ThemeTransparent}
          language={language}
          sourceId={sourceId}
          userIsLoggedIn={userIsLoggedIn}
        />
      </CookieBanner>
    </>
  );
};
