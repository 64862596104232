import { Router } from "@reach/router";
import {
  isAnalyticsSet,
  ThemeTransparent,
  Topbar,
} from "basic-frontend-common";
import {
  ANALYTICS_COOKIE_KEY,
  FooterComponent,
  GOOGLE_KEY,
  ICON_FILE,
  PAGE_AUTHOR,
  PAGE_DESCRIPTION,
  PAGE_KEYWORDS,
  PAGE_NAME,
  PAGE_TOPIC,
  SuspenseWrapper,
  ThemeMasterArchive,
} from "master-archive-common";
import React, { FC } from "react";
import { addPrefetchExcludes, Head, Root, Routes } from "react-static";
import { MenuItems } from "./includes";
import "./includes/master-archive.app.css";
import { ContentBySource } from "./pages/content-by-source";

addPrefetchExcludes(["sourceId"]);

const App: FC = () => {
  return (
    <>
      {typeof window !== "undefined" ? (
        <Root>
          <Head>
            {isAnalyticsSet(ANALYTICS_COOKIE_KEY) && (
              <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_KEY}`}
              ></script>
            )}
            <meta charSet="UTF-8" />
            <link rel="manifest" href="/manifest.json" />
            <link rel="icon" href={ICON_FILE} />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="application-name" content="Master Archive" />
            <meta name="apple-mobile-web-app-title" content="Master Archive" />
            <meta name="msapplication-starturl" content="/" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <meta name="theme-color" content="#000000" />
            <meta name="msapplication-navbutton-color" content="#000000" />
            <meta
              name="apple-mobile-web-app-status-bar-style"
              content="black-translucent"
            />
            <link rel="apple-touch-icon" href="/icon_192x192.png" />
            <meta name="page-topic" content={PAGE_TOPIC} />
            <meta name="robots" content="all" />
            <meta name="revisit-after" content="1 day" />
            <meta name="audience" content="all" />
            <meta http-equiv="expires" content="1 day" />
            <meta http-equiv="cache-control" content="no-cache" />
            {isAnalyticsSet(ANALYTICS_COOKIE_KEY) && (
              <meta
                name="msvalidate.01"
                content="773E07CCFD6C6311D3924E7B1A927C1F"
              />
            )}
            <meta
              name="description"
              content={`${PAGE_NAME} ${PAGE_DESCRIPTION}`}
            />
            <meta name="keywords" content={PAGE_KEYWORDS} />
            <meta name="author" content={PAGE_AUTHOR} />
            {isAnalyticsSet(ANALYTICS_COOKIE_KEY) && (
              <script>{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${GOOGLE_KEY}');`}</script>
            )}
            <script>{`if ('serviceWorker' in navigator) { window.addEventListener('load', function() { navigator.serviceWorker.register('/service-worker.js'); }); }`}</script>
          </Head>

          <Topbar
            branding={PAGE_NAME}
            theme={ThemeMasterArchive}
            menuItems={MenuItems()}
            footer={<FooterComponent theme={ThemeMasterArchive} />}
            stickyHeader
          >
            <SuspenseWrapper theme={ThemeTransparent}>
              <Router>
                <ContentBySource path="/sourceId/*" />
                <Routes path="*" />
              </Router>
            </SuspenseWrapper>
          </Topbar>
        </Root>
      ) : null}
    </>
  );
};

export default App;
