"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function get() {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  }

  __setModuleDefault(result, mod);

  return result;
};

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListContainer = void 0;

var react_1 = __importStar(require("react"));

var react_tagcloud_1 = require("react-tagcloud");

var list_item_1 = require("./list-item");

var ListContainer = function ListContainer(_a) {
  var list = _a.list,
      theme = _a.theme,
      language = _a.language;

  var _b = __read((0, react_1.useState)(), 2),
      facetContainer = _b[0],
      setFacetContainer = _b[1];

  var facetChange = function facetChange(e) {
    var newList = list.filter(function (f) {
      return f.catNameShort === e.value;
    });
    setFacetContainer(newList);
  };

  var loadTagCloudList = function loadTagCloudList() {
    var tagCloud = [];

    for (var _i = 0; _i < list.length; _i++) {
      tagCloud[_i] = {
        value: list[_i].catNameShort,
        count: facetContainer.filter(function (x) {
          return list[_i].catNameShort === x.catNameShort;
        }).length
      };
    }

    return tagCloud.filter(function (thing, i, arr) {
      return arr.indexOf(arr.find(function (t) {
        return t.value === thing.value;
      })) === i;
    });
  };

  var mappedItems = facetContainer && facetContainer.map(function (item, index) {
    return react_1["default"].createElement(list_item_1.ListItem, {
      item: item,
      key: index,
      theme: theme,
      language: language
    });
  });
  (0, react_1.useEffect)(function () {
    setFacetContainer(list);
  }, [list]);
  var options = {
    luminosity: "light",
    hue: "monochrome"
  };

  var customRenderer = function customRenderer(tag) {
    return react_1["default"].createElement("button", {
      key: tag.value,
      onClick: function onClick() {
        return facetChange;
      },
      className: "inline-block px-2 py-1 mr-1 mb-1 text-base font-medium leading-6 text-center text-gray-200 hover:text-black uppercase transition transparent border-gray-200 hover:border-black rounded-full shadow ripple hover:shadow-lg hover:shadow-gray-600 hover:bg-gray-200 focus:outline-none"
    }, tag.value);
  };

  return react_1["default"].createElement(react_1["default"].Fragment, null, facetContainer && react_1["default"].createElement(react_tagcloud_1.TagCloud, {
    minSize: 18,
    maxSize: 44,
    tags: loadTagCloudList(),
    shuffle: false,
    onClick: facetChange,
    colorOptions: options,
    renderer: customRenderer
  }), react_1["default"].createElement("ul", {
    className: "mt-1"
  }, facetContainer && mappedItems));
};

exports.ListContainer = ListContainer;