"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScrollHandler = void 0;

var ScrollHandler = function ScrollHandler(limitReached, scrollTrigger) {
  if (!limitReached && scrollTrigger >= 50) {
    var scrollTop = document.documentElement && document.documentElement.scrollTop || document.body.scrollTop;
    var scrollHeight = document.documentElement && document.documentElement.scrollHeight || document.body.scrollHeight;
    var clientHeight = document.documentElement.clientHeight || window.innerHeight;
    var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    return scrolledToBottom;
  }

  return false;
};

exports.ScrollHandler = ScrollHandler;